import {Component, OnInit} from '@angular/core';
import {CoreService, LookupLists} from "../../modules/core.service";

@Component({
  selector: 'app-dash',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  datasets: string[] = [];

  constructor(private coreSvc: CoreService) {
  }

  ngOnInit(): void {
    this.coreSvc.getLookupLists().subscribe(lookups => {
      this.datasets = lookups.dataSets.map(ds => ds.name);
    });
  }
}
