import {Component} from '@angular/core';

@Component({
    selector: 'ae-user-mgt-wrapper',
    templateUrl: './user-mgt-wrapper.component.html',
    styleUrls: []
})
export class UserMgtWrapperComponent {

}
