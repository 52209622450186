<div>
  <ngx-charts-bar-vertical-2d
    [animations]="enabledAnimations"
    [view]="view"
    [scheme]="colorScheme"
    [results]="chartData"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [legendTitle]="legendTitle"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
  >
  </ngx-charts-bar-vertical-2d>
</div>
