import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../user.service";

@Component({
  selector: 'ae-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  static defaultMessage = "You have been logged out of the portal...  Select the link below to log back in.";
  message: string = "";

  constructor(private api: UserService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    console.log('logout prior session');
    this.message = this.route.snapshot.queryParamMap.get("message") || LogoutComponent.defaultMessage;
    this.api.logout();
  }

  ngOnDestroy(): void {
  }

  public gotoLogin() {
    this.router.navigateByUrl("/login").catch(error => {
      this.router.navigate(['']).then(r => true);
    });
  }

}
