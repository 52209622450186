import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../user.service";
import {AppConfig} from "../../base/app-config";
import {ConfigService} from "../../base/config.service";
import {BaseService} from "../../base/base.service";
import {UserInfo} from "../user-models";

const unauthorizedAccessMessage =
  "The user is logged in but not authorized to access the FeaturePrint system. Please contact your FeaturePrint administrator to request access.";

@Component({
  selector: 'ae-authz',
  templateUrl: './auth-z.component.html',
  styleUrls: ['./auth-z.component.scss']
})
export class AuthZComponent implements OnInit, OnDestroy {
  constructor(
    private api: UserService,
    private config: ConfigService<AppConfig>,
    private base: BaseService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  authCodeCheckComplete = false;
  userAuthCheckComplete = false;
  error: any = null;
  token: any = null;

  ngOnInit(): void {
    this.processOauth();
  }

  private processOauth() {
    this.error = null;
    this.token = null;
    this.authCodeCheckComplete = false;
    this.userAuthCheckComplete = false;
    let parent = this;

    // check to see if we are returning from oath login and need to exchange the auth_code for a token
    const authCode = this.route.snapshot.queryParamMap.get("code");
    const errorDescription = this.route.snapshot.queryParamMap.get("error_description");

    // See: https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
    let errorCode = undefined;
    if (errorDescription && errorDescription.startsWith("AADB2C")) {
      errorCode = errorDescription.split(':')[0]
    }
    const router = this.router;
    if (errorCode) {
      switch (errorCode) {
        case 'AADB2C90118': {
          window.location.href = this.api.getOauth2ResetUserUrl();
          break;
        }
        default:
          console.warn(`Unhandled error code: ${errorCode}`)
      }
    } else if (authCode) {
      let redirectUrl = this.api.getPostLoginRedirectUrl();
      console.log("post auth redirect:", redirectUrl);

      const userInfoObserver = {
        next(userInfo: UserInfo) {
          const isSuperuser = userInfo.superuser;
          const authorizedSourceRoleNames = parent.config.getConfig().authorizedSourceRoleNames;
          const adminSourceRoleNames = parent.config.getConfig().adminSourceRoleNames;
          let found = isSuperuser
          if(found) {
            parent.userAuthCheckComplete = true;
          }

          if(!found) {
            for (let sourceRole of userInfo.sourceRoles?.map(sr => sr.sourceRole) || []) {
              if (authorizedSourceRoleNames.includes(sourceRole) || adminSourceRoleNames.includes(sourceRole)) {
                found = true
                parent.userAuthCheckComplete = true
              }
            }
          }
          if (!found) {
            parent.error = {
              message: unauthorizedAccessMessage
            }
            parent.base.clearAuthTokens()
            router.navigate(['/logout'], {queryParams: {message: parent.errorMessage()}}).then(r => true);
          }
          if (parent.isProcessingComplete() && !parent.error) {
            parent.navigateAway(redirectUrl)
          }
        }
      }

      const tokenObserver = {
        next(value: any) {
          console.log('auth code exchange', value);
          parent.token = value;
        },
        error(e: any) {
          console.log('auth code exchange error', e);
          parent.error = e;
          parent.base.clearAuthTokens()
          router.navigate(['/logout'], {queryParams: {message: parent.errorMessage()}}).then(r => true);
        },
        complete() {
          console.log('auth code exchange complete', parent.isProcessingComplete(), parent.error, parent);
          parent.authCodeCheckComplete = true;
          parent.api.currentUser().subscribe(userInfoObserver);
          if (parent.isProcessingComplete() && !parent.error) {
            parent.navigateAway(redirectUrl)
          }
          return false;
        }
      };

      this.api.exchangeAuthCode(authCode).subscribe(tokenObserver);
    } else {
      // redirect to /login
      router.navigate(['login']).then(r => true);
    }
  }

  errorMessage(): string | null {
    if (this.error) {
      let errorCode = this.error["errorCode"] || null;
      console.log(`errorMessage().errorCode: ${errorCode}`);
      if (errorCode && errorCode == "012-UNAUTHORIZED-ACCESS") {
        return unauthorizedAccessMessage;
      }
      let messageOptions: string[] = [
        this.error['userMessage'],
        this.error['message'],
      ]
      for (const msgOp of messageOptions) {
        if (msgOp && !msgOp.startsWith("*****")) {
          return msgOp
        }
      }
      return JSON.stringify(this.error)
    } else {
      return null
    }
  }

  navigateAway(redirectUrl: string|null) {
    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl).catch(error => {
        console.log("error processing specified route, falling back to default.", error);
        this.router.navigate(['']).then(r => true);
      });
    } else {
      this.router.navigate(['']).then(r => true);
    }
  }

  isProcessingComplete(): boolean {
    if (this.error) {
      return true
    }
    if (this.authCodeCheckComplete && this.userAuthCheckComplete) {
      return true
    }
    return false
  }

  ngOnDestroy(): void {
    //nothing to do...
  }
}
