<div>
  <mat-accordion>
    <mat-expansion-panel
      id="search-panel"
      class="search-panel"
      [expanded]="isExpanded"
      (opened)="isExpanded = true"
      (closed)="isExpanded = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ searchTitle }}
        </mat-panel-title>
        <mat-panel-description>
          <span class="panel-description-item">
            <b>Data Sets:</b> {{ dataSets.value }}
          </span>
          <span
            class="panel-description-item"
            *ngIf="includeDateSearch"
            ><b>Start:</b>
            {{ startDate.value | date : "yyyy-MM-dd HH:mm:ss" }}</span
          >
          <span
            class="panel-description-item"
            *ngIf="includeDateSearch"
            ><b>End:</b>
            {{ endDate.value | date : "yyyy-MM-dd HH:mm:ss" }}</span
          >
          <span
            class="panel-description-item"
            *ngIf="includeLabelSearch"
            ><b>Label:</b> {{ label.value }}</span
          >
          <span
            class="panel-description-item"
            *ngIf="includeCommercialRefSearch"
          ><b>Commercial Ref:</b> {{ commercialRef.value }}</span
          >
          <span
            class="panel-description-item"
            *ngIf="includeSerialNumberSearch"
          ><b>Serial Number:</b> {{ serialNumber.value }}</span
          >
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-2" *ngIf="includeDataSetSearch"
               [ngClass]="{ includeDateSearch: 'search-filter-divider' }"
          >
            <mat-form-field>
              <mat-label>Select DataSets</mat-label>
              <mat-select
                id="dataSets"
                [multiple]="false"
                [formControl]="dataSets"
              >
                <mat-option
                  *ngFor="let dataset of dataSetList"
                  [value]="dataset"
                >{{ dataset }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field *ngIf="includeDateSearch">
              <mat-label>Select Time Range</mat-label>
              <mat-select
                [formControl]="searchQuickRange"
                (selectionChange)="onChangeQuickSearch($event)"
              >
                <mat-option>None</mat-option>
                <mat-option
                  *ngFor="let range of quickSearchList"
                  [value]="range.rangeInMinutes"
                  >{{ range.display }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2" formGroupName="dateForm">
            <div *ngIf="includeDateSearch">
              <mat-form-field>
                <mat-label>Start Date:</mat-label>
                <input
                  matInput
                  id="pickerStart"
                  [ngxMatDatetimePicker]="pickerStart"
                  formControlName="startDate"
                  [formControl]="startDate"
                  (dateChange)="onChangeStartDate($event)"
                  [min]=null
                  [max]="endDate.value"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(pickerStart)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #pickerStart
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="false"
                  [disableMinute]="false"
                  [hideTime]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div *ngIf="includeDateSearch">
              <mat-form-field>
                <mat-label>End Date:</mat-label>
                <input
                  matInput
                  id="pickerEnd"
                  [ngxMatDatetimePicker]="pickerEnd"
                  formControlName="endDate"
                  [formControl]="endDate"
                  (dateChange)="onChangeEndDate($event)"
                  [min]="startDate.value"
                  [max]=null
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(pickerEnd)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #pickerEnd
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="true"
                  [disableMinute]="false"
                  [hideTime]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div *ngIf="dateForm.errors?.['isInvalidDate']" class="ng-invalid">
              Start date must be prior to end date
            </div>
          </div>
          <div class="col-md-6">
            <mat-form-field *ngIf="includeCommercialRefSearch">
              <mat-label>Commercial Ref</mat-label>
              <input matInput [formControl]="commercialRef" [value]=null (change)="upperCommercialRef()" />
            </mat-form-field>
            <mat-form-field *ngIf="includeSerialNumberSearch">
              <mat-label>Serial Number</mat-label>
              <input matInput [formControl]="serialNumber" [value]=null (change)="upperSerialNumber()" class="search-filter-divider"/>
            </mat-form-field>
            <mat-form-field *ngIf="includeLabelSearch">
              <mat-label>Label</mat-label>
              <input matInput [formControl]="label" [value]=null (change)="upperLabel()" />
            </mat-form-field>
            <mat-form-field *ngIf="includeStatusSearch">
              <mat-label>Select Status</mat-label>
              <mat-select
                id="status"
                [multiple]="false"
                [formControl]="statusCtl"
              >
                <mat-option
                  *ngFor="let status of statusList"
                  [value]="status.val"
                >{{ status.display }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" class="bottom-bar-right">
          <div class="col-md-12">
            <button mat-stroked-button color="warn" type="reset" (click)="resetSearch()">
              reset
            </button>
            <button mat-stroked-button (click)="cancelSearch()">cancel</button>
            <button mat-stroked-button color="primary" *ngIf="canSearch()">
              search
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
