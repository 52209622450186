import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SearchCriteria } from '../../modules/record/portal-api.service';
import {
  StatusSearchItem,
} from '../../modules/core.service';
import { DataSetContext } from '../../modules/record/context-models';
import { MatSelectChange } from '@angular/material/select';
import { dateRangeValidator } from './search-filter-component.directive';
import {DatasetSourceRoles} from "../../modules/user/user-models";

export class QuickSearchRangeItem {
  rangeInMinutes: number;
  display: string;

  constructor(rangeInMinutes: number, display: string) {
    this.rangeInMinutes = rangeInMinutes;
    this.display = display;
  }
}

@Component({
  selector: 'ae-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  currentDataset: string | null = null;

  @Output() searchEvent: EventEmitter<SearchCriteria> =
    new EventEmitter<SearchCriteria>();
  @Output() resetSearchEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() searchTitle!: string;
  @Input() includeDateSearch = false;
  @Input() includeLabelSearch = false;
  @Input() includeCommercialRefSearch = false;
  @Input() includeSerialNumberSearch = false;
  @Input() includeDataSetSearch = true;
  @Input() includeStatusSearch = false;
  @Input() stationList: string[] = [];
  @Input() dataSetList?: string[] = [];
  @Input() statusList: StatusSearchItem[] = [{ val: null, display: 'all' }];
  @Input() quickSearchList: QuickSearchRangeItem[] = [
    { rangeInMinutes: 5, display: 'Last 5 Minutes' },
    { rangeInMinutes: 10, display: 'Last 10 Minutes' },
    { rangeInMinutes: 15, display: 'Last 15 Minutes' },
    { rangeInMinutes: 20, display: 'Last 20 Minutes' },
    { rangeInMinutes: 30, display: 'Last 30 Minutes' },
    { rangeInMinutes: 60, display: 'Last Hour' },
    { rangeInMinutes: 120, display: 'Last 2 Hours' },
    { rangeInMinutes: 240, display: 'Last 4 Hours' },
    { rangeInMinutes: 360, display: 'Last 6 Hours' },
    { rangeInMinutes: 1440, display: 'Last Day' },
    { rangeInMinutes: 10080, display: 'Last Week' },
  ];
  @Input() isExpanded = true;

  @Input() set setStartDate(val: Date) {
    this.startDate.setValue(val);
  }

  @Input() set setEndDate(val: Date) {
    this.endDate.setValue(val);
  }

  @Input() set setDataSets(val: string[]) {
    this.dataSets.setValue(val);
  }

  @Input() set setStatus(val: string) {
    this.statusCtl.setValue(val);
  }

  @Input() set setLabel(val: string) {
    this.label.setValue(val);
  }

  @Input() set setCommercialRef(val: string) {
    this.commercialRef.setValue(val);
  }

  @Input() set setSerialNumber(val: string) {
    this.serialNumber.setValue(val);
  }

  searchForm: FormGroup;
  searchQuickRange = new FormControl();
  startDate = new FormControl();
  endDate = new FormControl();
  dataSets = new FormControl();
  statusCtl = new FormControl();
  label = new FormControl();
  commercialRef = new FormControl();
  serialNumber = new FormControl();
  dateForm = new FormGroup({
    startDate: this.startDate,
    endDate: this.endDate,
  },[dateRangeValidator]);

  constructor(formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      quickSearch: this.searchQuickRange,
      dateForm: this.dateForm,
      dataSets: this.dataSets,
      status: this.statusCtl,
      label: this.label,
      commercialRef: this.commercialRef,
      serialNumber: this.serialNumber
    });
  }

  ngOnInit(): void {
    this.dataSets.valueChanges.subscribe((value: string) => {
      const newVal = value;
      this.currentDataset = newVal;
    });

    // init search range with first value
    this.searchQuickRange.setValue(null);
  }

  onSubmit(): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.isExpanded = false;
    const formValue = this.searchForm.value;
    const dateValue = this.dateForm.value;
    const dataset = formValue.dataSets;
    const label = formValue.label;
    const commercialRef = formValue.commercialRef;
    const serialNumber = formValue.serialNumber;
    const status = formValue.status ? formValue.status : null;
    this.setDateControls();
    const searchCrit = new SearchCriteria(
      dataset,
      dateValue.startDate,
      dateValue.endDate,
      label,
      commercialRef,
      serialNumber
    );
    this.searchEvent.emit(searchCrit);
  }

  upperSerialNumber():void {
    this.serialNumber.setValue(this.serialNumber.value.toUpperCase());
  }

  upperCommercialRef():void {
    this.commercialRef.setValue(this.commercialRef.value.toUpperCase());
  }

  upperLabel():void {
    this.label.setValue(this.label.value.toUpperCase());
  }

  canSearch(): boolean {
    return this.dataSets.value != null &&
      (!this.includeDateSearch || (this.includeDateSearch && this.startDate.value != null && this.endDate.value != null))
  }

  cancelSearch(): void {
    this.isExpanded = false;
  }

  resetSearch(): void {
    this.searchForm.reset();
    this.resetSearchEvent.emit();
  }

  onChangeStartDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeEndDate(event: any): void {
    this.searchQuickRange.setValue(null);
  }

  onChangeQuickSearch(event: MatSelectChange): void {
    if (!event.value) {
      this.startDate.setValue(null);
      this.endDate.setValue(null);
      return;
    }
    this.setDateControls();
  }

  private setDateControls(): void {
    if (this.searchQuickRange.value) {
      const endMillis = Date.now();
      const startMillis = endMillis - this.searchQuickRange.value * 60 * 1000;
      this.endDate.setValue(new Date(endMillis));
      this.startDate.setValue(new Date(startMillis));
    }
  }
}
