import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {BaseService} from '../base/base.service';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {throwError} from "rxjs";
import {AccessToken, TokenInfo} from "../base/access-token";
import {DatasetSourceRoles, UserInfo, UserInfoResponse} from "./user-models";

@Injectable()
export class UserService implements OnInit {
  private userManagerSourceRole = "USER-MANAGEMENT"

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  ngOnInit() {
    console.log("config", this.baseSvc.appConfig())
  }

  private readonly currentUserSubject: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);
  private readonly isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isAdmin(): Observable<boolean> {
    return this.isAdminSubject.asObservable();
  }

  public currentUser(): Observable<UserInfo> {
    if (!this.currentUserSubject.value) {
      return this.getLoggedInUser().pipe(switchMap(() => this.currentUserSubject.asObservable() as BehaviorSubject<UserInfo>));
    } else {
      return (this.currentUserSubject as BehaviorSubject<UserInfo>).asObservable();
    }
  }

  public logout(): void {
    this.baseSvc.clearAuthTokens();
    this.currentUserSubject.next(null);
    this.isAdminSubject.next(false);
  }

  public exchangeAuthCode(authCode: string): Observable<any> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/token-exchange/auth-code`);
    const payload = {
      authCode: authCode,
      customerName: config.customerName
    };

    const req: Observable<any> = this.http.post(url, payload)
      .pipe(
        tap((response: any) => this.processLoginResponse(response))
      );
    return req;
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.baseSvc.getAccessToken()?.refreshToken;
    if (!refreshToken) {
      const error = {
        status: 401,
        error: {
          message: 'Expired Credentials, Requires Login',
          userMessage: 'Expired Credentials, Requires Login'
        }
      }
      return throwError(() => error);
    }
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/token-exchange/refresh`);
    const payload = {
      "customerName": config.customerName,
      "refreshToken": this.baseSvc.getAccessToken()?.refreshToken
    };
    const req: Observable<any> = this.http
      .post(url, payload)
      .pipe(
        tap((response: any) => this.processLoginResponse(response))
      );
    return req;
  }

  public listUsers(dataset:string): Observable<UserInfo[]> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/${config.customerName}/${dataset}/${this.userManagerSourceRole}/user-list`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((auths: any) => auths as UserInfo[])
      );
    return req;
  }

  public getLoggedInUser(): Observable<UserInfo> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`profile/v1/${config.customerName}/user-info`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        tap((response: any) => this.processUserInfoResponse(response as UserInfoResponse))
      );
    return req;
  }


  public getUser(userId:string, dataset:string): Observable<UserInfo> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/${config.customerName}/${dataset}/${this.userManagerSourceRole}/user?userId=${userId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => {
          return this.mapUser(response);
        })
      );
    return req;
  }

  /*
          val email: String,
        val superuser: Boolean,
        val enabled: Boolean,
   */
  public saveUser(email: string, enabled: boolean): Observable<UserInfo> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/${config.customerName}/${this.userManagerSourceRole}/user`);
    const req: Observable<any> = this.http
      .put(url, {
        email: email,
        enabled: enabled,
        superuser: false
      }, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => this.mapUser(response))
      );
    return req;
  }

  public addSourceRole(userId: string, sourceRole: string, dataset:string): Observable<void> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/${config.customerName}/${dataset}/${this.userManagerSourceRole}/add-source-role/${userId}/${sourceRole}`);
    const req: Observable<any> = this.http
      .put(url, null, this.baseSvc.defaultOptions())
    ;
    return req;
  }

  public removeSourceRole(userId: string, sourceRole: string, dataset:string): Observable<void> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.gatewayUrl(`authz/v1/${config.customerName}/${dataset}/${this.userManagerSourceRole}/remove-source-role/${userId}/${sourceRole}`);
    const req: Observable<any> = this.http
        .delete(url, this.baseSvc.defaultOptions())
    ;
    return req;
  }

  //Not Currently supported
  public deleteUser(userId: string): Observable<void> {
    const url = this.baseSvc.customerUrl(`profile/user/${userId}`);
    const req: Observable<any> = this.http
      .delete(url, this.baseSvc.defaultOptions());
    return req;
  }

  public listAvailableDataSets():Observable<DatasetSourceRoles[]> {
    const url = this.baseSvc.gatewayUrl(`profile/v1/${this.baseSvc.appConfig().customerName}/PORTAL-USER/list-available-datasets`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => response.assignableDatasets as DatasetSourceRoles[])
      );
    return req;
  }

  public listAssignableDataSets(): Observable<DatasetSourceRoles[]> {
    const url = this.baseSvc.gatewayUrl(`profile/v1/${this.baseSvc.appConfig().customerName}/USER-MANAGEMENT/list-assignable-datasets`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => response.assignableDatasets as DatasetSourceRoles[])
      );
    return req;
  }

  public getOauth2LoginUrl(): string {
    return this.baseSvc.appConfig().oauth2LoginUrl!!;
  }

  public getOauth2ResetUserUrl(): string {
    return this.baseSvc.appConfig().oauth2ResetUserUrl!!;
  }


  public setPostLoginRedirectUrl(redirectUrl: string) {
    localStorage.setItem('post-login-redirect', redirectUrl);
  }

  public getPostLoginRedirectUrl(): string | null {
    let url = localStorage.getItem('post-login-redirect');
    if (url) {
      url = url.trim();
    }
    return url == "" ? null : url;
  }

  private mapUser(raw: UserInfo): UserInfo {
    return raw;
  }

  private mapToken(raw: any): TokenInfo {
    return raw.tokenInfo as TokenInfo;
  }

  private processLoginResponse(response: any) {
    const accessToken = AccessToken.fromTokenInfo(response.tokenDetails);
    this.baseSvc.setToken(accessToken);
    const userInfo = UserInfo.fromUserInfoGetResponse(response.principalGrant as UserInfoResponse);
    this.broadcastUserUpdate(userInfo)
  }

  private processUserInfoResponse(response: any) {
    const userInfo = UserInfo.fromUserInfoGetResponse(response as UserInfoResponse);
    this.broadcastUserUpdate(userInfo)
  }

  private broadcastUserUpdate(newUser: UserInfo): void {
    const currentUser = this.currentUserSubject.value;
    if (currentUser !== newUser) {
      this.currentUserSubject.next(newUser);
      this.broadcastIsAdmin();
    }
  }

  private broadcastIsAdmin(): void {
    //NOTE right now only superuser can manage users
    // otherwise the dataset will have to be selected outside of user-management to know if the logged in user is an admin for the dataset selected
    const adminRoles = this.baseSvc.appConfig().adminSourceRoleNames;
    const isSuperUser = this.currentUserSubject?.value?.superuser || false;
    this.isAdminSubject
      .next(isSuperUser);
  }
}
