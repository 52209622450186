import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {UserModule} from "./user/user.module";
import {RecordModule} from "./record/record.module";
import {UtilityModule} from "./utility/utility.module";
import {BaseModule} from "./base/base.module";
import {CoreService} from "./core.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    UserModule,
    RecordModule,
    UtilityModule,
    BaseModule
  ],
  providers: [CoreService],
  exports: [],
  bootstrap: []
})
export class CoreModule {
}
