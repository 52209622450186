import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subscription} from 'rxjs/internal/Subscription';
import {timer} from 'rxjs/internal/observable/timer';
import {AppConfig} from "../base/app-config";
import {ConfigService} from "../base/config.service";
import {DemoConfig} from "../../app.module";

@Injectable()
export class PeriodicRefreshService {
  private readonly refreshDefault: number = 90;
  private readonly refreshSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.refreshDefault);
  private refreshSub: Subscription;
  private lastRefreshed = new Date();
  private readonly lastRefreshedSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(this.lastRefreshed);

  constructor(private configSvc: ConfigService<AppConfig>) {
    const refreshValue = this.configSvc.getConfig().refresh || this.refreshDefault
    this.refreshSub = timer(1, refreshValue * 1000).subscribe(() => {
      this.lastRefreshed = new Date();
      this.lastRefreshedSubject.next(this.lastRefreshed);
    });
  }

  public getRefreshRate(): Observable<number> {
    return this.refreshSubject;
  }

  public setRefreshRate(rateInSeconds: number): void {
    console.log('service set refresh', rateInSeconds);
    this.refreshSubject.next(rateInSeconds);
  }

  public getLastRefreshed(): Observable<Date> {
    return this.lastRefreshedSubject;
  }
}
