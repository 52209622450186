/* tslint:disable:no-console */
import {Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {timer, zip} from 'rxjs';
import {Page, PortalApiService, SearchCriteria} from '../../modules/record/portal-api.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {CoreService, LookupLists} from "../../modules/core.service";
import {
  Identification,
  IdentificationSearchResult,
  IdentificationSelector
} from "../../modules/record/identification-models";
import {IdentificationWithDataset} from "../identification/identification.component";

@Component({
  selector: 'ae-identification-list',
  templateUrl: './identification-list.component.html',
  styleUrls: ['./identification-list.component.scss']
})
export class IdentificationListComponent implements OnInit {
  currentDisplayId!: string | null;
  currentIdentificationId: string | null = null;
  selectedIdentification: IdentificationSelector | null = null;
  currentIdentification: Identification | null = null;
  currentIdentificationWithDataset: IdentificationWithDataset | null = null;
  searchResult: IdentificationSearchResult[] | null = null;
  searchDataset: string | null = null;
  lookups: LookupLists = new LookupLists([]);
  searchCriteria!: SearchCriteria | null;
  datasetNames: string[] = [];

  totalAuthenticationsLength = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 25, 50];
  @ViewChild('paginator')
  paginator!: MatPaginator;
  @ViewChild('authTop')
  authTopElement!: ElementRef;
  @ViewChildren('authList')
  authListElements!: QueryList<ElementRef>;

  constructor(private recordApi: PortalApiService, private coreSvc: CoreService) {
  }

  @Input() set authenticationsList(list: IdentificationSearchResult[]) {
    if (list) {
      this.searchResult = list;
      if (this.searchResult.length > 0) {
        this.selectTopOfList();
      } else {
        this.currentDisplayId = null;
      }
    }
  }

  ngOnInit(): void {
    this.coreSvc.getLookupLists().subscribe(lookups => {
      this.lookups = lookups;
      this.datasetNames = this.lookups.dataSets.map(dsRole => dsRole.name);
    });
  }

  public searchHandler(searchCriteria: SearchCriteria): void {
    this.searchResult = null;
    this.currentDisplayId = null;
    this.searchCriteria = searchCriteria;
    this.doSearch();
  }

  pageHandler(event: PageEvent): void {
    this.currentDisplayId = null;
    this.pageSize = this.paginator.pageSize;
    this.doSearch();
  }

  doSearch(): void {
    if (this.searchCriteria) {
      this.searchDataset = this.searchCriteria.dataset;
      this.searchCriteria.page = new Page(this.paginator?.pageIndex || 0, this.pageSize);
      const searchStream = this.recordApi.searchIdentifications(this.searchCriteria);
      const countStream = this.recordApi.identificationSearchCount(this.searchCriteria);
      zip(searchStream, countStream).subscribe({
        next: ([records, cnt]) => {
          this.searchResult = records;
          this.totalAuthenticationsLength = cnt;
        },
        error: errors => {
          this.totalAuthenticationsLength = 0;
          this.searchResult = [];
          console.log('error searching authentications', errors);
        }
      });
    }
  }

  protected clearCapture(): void {
    this.currentDisplayId = null;
    this.currentIdentification = null;
    this.currentIdentificationWithDataset = null;
    this.selectedIdentification = null;
  }

  protected viewCaptureAndScroll(id: string, i: number, scroll: boolean): void {
    if (i > -1 && id && this.searchDataset) {
      this.currentIdentificationId = id;
      this.recordApi.getIdentification(id, this.searchDataset)
        .subscribe(identification => {
          this.currentIdentification = identification
          this.currentIdentificationWithDataset = {identification: this.currentIdentification, dataset: this.searchDataset!};
          this.selectedIdentification = {identificationId: this.currentIdentificationId!, dataset: this.searchDataset!};
        });
    } else {
      this.currentIdentification = null;
      this.selectedIdentification = null;
      this.currentIdentificationWithDataset = null;
    }
    this.currentDisplayId = id;
    if (scroll && id) {
      timer(50).subscribe(() => {
        this.authListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.authTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected viewCapture(i: number, id?: string): void {
    if (!id) {
      return;
    }
    const doScroll: boolean = this.currentDisplayId ? false : true;
    this.viewCaptureAndScroll(id, i, doScroll);
  }

  private selectTopOfList(): void {
    if (!this.searchResult || this.searchResult.length === 0) {
      return;
    }

    const list = this.searchResult;

    if (list.length > 0) {
      let index = 0;
      if (this.currentDisplayId) {
        index = list.findIndex(r => r.identificationId === this.currentDisplayId);
        index = index < 0 ? 0 : index;
      }
      this.viewCaptureAndScroll(list[index]?.identificationId as string, index, true);
    } else {
      this.clearCapture();
    }
  }
}
