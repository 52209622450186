import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {PeriodicRefreshService} from './modules/utility/periodic-refresh.service';

@Component({
  selector: 'ae-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ac-demo-ui';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private refreshSvc: PeriodicRefreshService
  ) {
  }

  public now: Date = new Date();
  public lastRefreshed: Date | null = null;
  private refreshSub: Subscription | null = null;

  ngOnInit(): void {
    this.refreshSub = this.refreshSvc
      .getLastRefreshed()
      .subscribe((refreshed) => {
        this.lastRefreshed = refreshed;
      });
  }

  ngOnDestroy(): void {
    console.log('app-teardown');
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
  }
}
