export class GatewayException {
  traceId: string;
  status?: number;
  messages?: string[];
  errorCode?: string;

  constructor(traceId: string, callName: string, status?: number, messages?: string[], errorCode?: string) {
    this.traceId = traceId;
    this.status = status;
    this.messages = messages;
    this.errorCode = errorCode;
  }

  public static fromError(httpError: any): GatewayException {
    let ae = new GatewayException("n/a", "n/a", 0, [], undefined);
    try {
      const error = httpError.error;
      ae.traceId = error.traceId ? error.traceId : ae.traceId;
      ae.status = (httpError.status || !isNaN(parseInt(httpError.status))) ? httpError.status : null;
      ae.messages = error.message ? error.messages : ae.messages;
      ae.errorCode = error.errorCode ? error.errorCode : ae.errorCode;
    } catch (e) {
      console.log("error handle exception:", e, ae);
    }
    return ae;
  }
}
