import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {zip} from "rxjs";
import {UserService} from "./user/user.service";
import {PortalApiService} from "./record/portal-api.service";
import {map} from "rxjs/operators";
import {DataSetContext} from "./record/context-models";
import {DatasetSourceRoles, UserInfo} from "./user/user-models";

export class LookupLists {
  dataSets: DatasetSourceRoles[] = [];
  contextDataSetMap: Map<string, string> = new Map<string, string>();
  statusList: StatusSearchItem[] = [];
  authenticationStatusList: StatusSearchItem[] = [];
  contexts: DataSetContext[] = [];

  constructor(dataSets: DatasetSourceRoles[]) {
    this.dataSets = dataSets;
    this.statusList = [{val: null, display: "All"}, {val: "ENABLED", display: "ENABLED"}, {
      val: "DISABLED",
      display: "DISABLED"
    }];
    this.authenticationStatusList = [{val: null, display: "All"}, {val: "COMPLETED", display: "COMPLETED"}];
  }

  public static emptyList(): LookupLists {
    return new LookupLists([]);
  }
}

export class StatusSearchItem {
  val: string | null;
  display: string;

  constructor(val: string | null, display: string) {
    this.val = val
    this.display = display
  }
}

@Injectable()
export class CoreService {
  constructor(
    private userSvc: UserService,
    private recordSvc: PortalApiService) {
  }

  public getLookupLists(): Observable<LookupLists> {
    const currentUserStream = this.userSvc.currentUser();
    const userDataSetStream = this.userSvc.listAvailableDataSets();

    const tempStreams = [currentUserStream, userDataSetStream]
    const streams = zip(...tempStreams);

    return streams.pipe(
      map(([currentUser, dataSetList]) => {
        const user = currentUser as UserInfo;
        const dataSets = dataSetList as DatasetSourceRoles[];

        const lookups = new LookupLists(dataSets);
        return lookups;

      }, (errors: any) => {
        console.log('Error retrieving lookup lists', ...errors);
      })
    );
  }
}
