import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CountsByDay, PortalApiService} from '../../modules/record/portal-api.service';
import {PeriodicRefreshService} from '../../modules/utility/periodic-refresh.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {of, zip} from 'rxjs';
import {Color, LegendPosition, ScaleType} from '@swimlane/ngx-charts';
import {Observable} from "rxjs/internal/Observable";

@Component({
  selector: 'ae-fp-chart',
  templateUrl: './featureprint-chart.component.html',
  styleUrls: ['./featureprint-chart.component.scss']
})
export class FeatureprintChartComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription | null = null;
  private dataSets: Array<string> = [];

  @Input() set setDataSets(val: string[]) {
    if (val && val != this.dataSets) {
      this.dataSets = val;
      this.refreshData();
    }
  }

  private streamSub: Subscription = new Subscription;
  chartData: Array<any> = [];

  view: [number, number] = [1200, 700];

  // options
  enabledAnimations = true
  legendPosition = LegendPosition.Right
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Day';
  showYAxisLabel = true;
  yAxisLabel = 'Counts';
  legendTitle = '';


  color = ['#174375', '#5779aa', '#007CC1', '#3399d1', '#808080', '#d3d3d3', '#8dd6f0', '#61c3e9']

  colorScheme: Color = {
    name: 'Variation',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: this.color
  }

  onSelect(data: any): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  constructor(private recordApi: PortalApiService,
              private refreshSvc: PeriodicRefreshService) {
  }

  ngOnInit(): void {
    this.refreshData();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      this.refreshData();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
    if (this.streamSub) {
      this.streamSub.unsubscribe();
    }
  }

  private refreshData() {
    if (this.streamSub) {
      this.streamSub.unsubscribe();
    }
    this.getChartData();
  }

  private seedChartRange(endDate: Date = new Date(), datasets: string[]): any[] {
    const data = [];
    const end = endDate.getTime();
    for (let day = 6; day > -1; day--) {
      const dateMillis = end - (day * this.recordApi.dayInMillis);
      const dt = (new Date(dateMillis)).toISOString();
      const dateName = (dt).split('T')[0]; // ex "2020-10-01T13:00:00.123" -> "2020-10-01"

      let seriesData: any[] = [];
      datasets.forEach(dataset => {
        seriesData.push(
          {name: `${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")} FP`, value: 0},
          {name: `${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")} ID`, value: 0}
        );
      });
      data.push({
        name: dateName,
        series: seriesData
      });
    }

    return data;
  }

  private getChartData(): void {

    if (this.dataSets && this.dataSets.length > 0) {
      const seriesNames: string[] = [];
      const streams: Observable<CountsByDay[]>[] = [];

      const endDate = new Date();
      const startDate = new Date(endDate.getTime() - (6 * this.recordApi.dayInMillis));

      const data = this.seedChartRange(endDate, this.dataSets);

      //get data for each dataset and append to graph data as it comes in
      this.dataSets.forEach(dataset => {
      //   let fpCountByDay: CountsByDay[] = [];
      //   let idCountsByDay: CountsByDay[] = [];
      //   for (let i = 0; i < 7; i++) {
      //     const date = new Date(startDate.getTime() + (i * this.recordApi.dayInMillis));
      //     fpCountByDay.push({
      //       day: date.toISOString().split('T')[0],
      //       count: Math.floor(Math.random() * 100)
      //     });
      //     idCountsByDay.push({
      //       day: date.toISOString().split('T')[0],
      //       count: Math.floor(Math.random() * 30)
      //     });
      //   }
      //   const fpStream = of<CountsByDay[]>(fpCountByDay);
      //   const idStream = of<CountsByDay[]>(idCountsByDay);
        const fpStream = this.recordApi.featurePrintCountsByDay(dataset, startDate, endDate);
        const idStream = this.recordApi.identificationCountsByDay(dataset, startDate, endDate);
        streams.push(fpStream);
        streams.push(idStream)
        seriesNames.push(`${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")} FP`);
        seriesNames.push(`${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")} FP`);
      });

      zip(streams).subscribe({
        next: (countsList) => {
          countsList.forEach((countsResult, i) => {
            countsResult.forEach(fpCount => {
              const idx = data.findIndex(d => d.name === fpCount.day);
              // featureprints are first item in series
              if (idx > -1) {
                //replace if exists looking up the series ie dataset plus `-fp` or `-id`
                const datasetIndex = data[idx].series.findIndex((n: {
                  name: string;
                }) => n.name == seriesNames[i])
                if (datasetIndex > -1) {
                  let cnt = fpCount.count;
                  data[idx].series[datasetIndex].value = cnt;
                }
              }
            });
          })
          this.chartData = data;

        },
        error: (error) => {
          console.log(`error getting chart counts ${error}`);
        }
      });
    }
  }

}
