import {Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {IdentificationComponent} from '../components/identification/identification.component';
import {LogoutComponent} from './user/logout/logout.component';
import {IdentificationListComponent} from '../components/identification-list/identification-list.component';
import {StockItemListComponent} from '../components/stock-item-list/stock-item-list.component';
import {StockItemComponent} from '../components/stock-item/stock-item.component';
import {BaseService} from "./base/base.service";
import {AuthZComponent} from "./user/authz/auth-z.component";
import {UserService} from "./user/user.service";
import {UserMgtWrapperComponent} from "../components/user-mgt-wrapper/user-mgt-wrapper.component";
import {LoginComponent} from "./user/login/login.component";

/*
  todo: if we continue with Angular the route guard will need to be updated to use a functional guard as the class based is depricated and will eventually be removed
   See https://linear.app/alitheon/issue/BK-158/update-portal-authguard-routing-to-functional-paradigm
*/
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private baseSvc: BaseService, private api: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let _this = this;
    if (this.baseSvc.isTokenExpired()) {
      const refreshObserver = {
        next(value: any) {
          //console.log('auth guard token refresh', value);
          return true;
        },
        error(e: any) {
          console.log('auth guard token refresh error', e);
          _this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}}).then(r => true);
          return false;
        },
        complete() {
          console.log('auth guard token refresh complete');
          return true;
        }
      };
      console.log("authGuard calling token refresh");
      this.api.refreshToken().subscribe(refreshObserver);
    }

    // not logged in so redirect to login page with the return url
    //this.router.navigate(['/authz'], {queryParams: {redirectUrl: state.url}}).then(r => true);
    return true;
  }

}

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authz',
    component: AuthZComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user-mgt',
    component: UserMgtWrapperComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'identifications',
    component: IdentificationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'identification/:dataset/:identificationId',
    component: IdentificationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'featurePrints',
    component: StockItemListComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'featurePrint/:dataset/:stockItemId',
    component: StockItemComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}

