import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from '../app-routing.module';
import {PortalApiService} from "./portal-api.service";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AppRoutingModule
  ],
  declarations: [],
  exports: [],
  providers: [
    PortalApiService
  ]
})
export class RecordModule {
}
