import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateRangeValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const startDate = control.get('startDate');
  const endDate = control.get('endDate');

  return startDate?.value && endDate?.value && startDate?.value > endDate?.value
    ? { isInvalidDate: true }
    : null;
};
