import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base/base.service';
import {map} from 'rxjs/operators';
import {ConfigService} from "../base/config.service";
import {AppConfig} from "../base/app-config";
import {Facet, StockItem, StockItemSearchResult} from "./feature-print-models";
import {Identification, IdentificationSearchResult} from "./identification-models";

const PORTAL_USER_ROLE = 'PORTAL-USER';

export class Page {
  pageNumber: number;
  pageSize: number;

  constructor(pageNumber: number, pageSize: number) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export class SearchCriteria {
  dataset: string | null;
  startDate: Date;
  endDate: Date;
  label: string | null;
  commercialReference: string | null;
  serialNumber: string | null;
  page: Page | null;

  constructor(
    dataset: string,
    startDate: Date,
    endDate: Date,
    label: string | null = null,
    commercialRef: string | null = null,
    serialNumber: string | null = null,
    page: Page | null = null
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.dataset = dataset;
    this.label = label;
    this.commercialReference = commercialRef;
    this.serialNumber = serialNumber;
    this.page = page;
  }
}

export class Counts {
  count: number;

  constructor(counts: number) {
    this.count = counts;
  }
}

export class CountsByDay {
  day: string;
  count: number;

  constructor(date: string, count: number) {
    this.day = date;
    this.count = count;
  }
}

@Injectable()
export class PortalApiService {

  constructor(private baseSvc: BaseService, private configSvc: ConfigService<AppConfig>, private http: HttpClient) {
  }

  public dayInMillis = (1000 * 60 * 60 * 24);

  public computePreviewUrl(previewKey: string, dataset: string): string {
    return this.baseSvc.gatewayUrl(`v1/${this.baseSvc.appConfig().customerName}/${dataset}/${PORTAL_USER_ROLE}/generate-download-url?assetType=preview&assetKey=${previewKey}`);
  }

  public getPreviewUrl(previewKey: string, dataset: string): Observable<string> {
    const url = this.baseSvc.gatewayUrl(`v1/${this.baseSvc.appConfig().customerName}/${dataset}/${PORTAL_USER_ROLE}/generate-download-url?assetType=preview&assetKey=${previewKey}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((url: any) => url as string)
      );

    return req;
  }

  public getStockItem(stockItemId: string, dataset: string): Observable<StockItem> {

    const url = this.baseSvc.customerUrl(`${dataset}/${PORTAL_USER_ROLE}/stock-item-view/${stockItemId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((stockItem: any) => stockItem as StockItem)
      );

    return req;
  }

  public getFacets(stockItemId: string, dataset: string): Observable<Facet[]> {

    const url = this.baseSvc.customerUrl(`${dataset}/${PORTAL_USER_ROLE}/stock-item-view/${stockItemId}/facets`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((facets: any) => facets as Facet[])
      );

    return req;
  }

  public searchStockItems(search: SearchCriteria): Observable<StockItemSearchResult[]> {

    const url = this.baseSvc.customerUrl(`${search.dataset}/${PORTAL_USER_ROLE}/featureprint-view/search`);
    let searchParams: any = {};
    searchParams.start = search.startDate.toISOString();
    searchParams.end = search.endDate.toISOString();
    searchParams.dataset = search.dataset;

    if (search.page) {
      searchParams['offset'] = search.page.pageNumber * search.page.pageSize;
      searchParams['limit'] = search.page.pageSize;
    }
    if (search.commercialReference && search.commercialReference.length > 0) {
      searchParams.commercialReference = search.commercialReference;
    }
    if (search.serialNumber && search.serialNumber.trim() !== '') {
      searchParams.serialNumber = search.serialNumber;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((featurePrints: any) => featurePrints as StockItemSearchResult[])
      );

    return req;
  }

  public featurePrintSearchCount(search: SearchCriteria): Observable<number> {
    const url = this.baseSvc.customerUrl(`${search.dataset}/${PORTAL_USER_ROLE}/featureprint-view/search/count`);
    let searchParams: any = {};
    searchParams.start = search.startDate.toISOString();
    searchParams.end = search.endDate.toISOString();
    searchParams.dataset = search.dataset;

    if (search.page) {
      searchParams['offset'] = search.page.pageNumber;
      searchParams['limit'] = search.page.pageSize;
    }
    if (search.commercialReference && search.commercialReference.length > 0) {
      searchParams.commercialReference = search.commercialReference;
    }
    if (search.serialNumber && search.serialNumber.trim() !== '') {
      searchParams.serialNumber = search.serialNumber;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((total: any) => total as number)
      );

    return req;
  }

  public getIdentification(identificationId: string, dataset: string): Observable<Identification> {

    const url = this.baseSvc.customerUrl(`${dataset}/${PORTAL_USER_ROLE}/identification-view/${identificationId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((identification: any) => identification as Identification)
      );

    return req;
  }

  public searchIdentifications(search: SearchCriteria): Observable<IdentificationSearchResult[]> {

    const url = this.baseSvc.customerUrl(`${search.dataset}/${PORTAL_USER_ROLE}/identification-view/search`);
    let searchParams: any = {};
    searchParams.start = search.startDate.toISOString();
    searchParams.end = search.endDate.toISOString();
    searchParams.dataset = search.dataset;

    if (search.page) {
      searchParams['offset'] = search.page.pageNumber * search.page.pageSize;
      searchParams['limit'] = search.page.pageSize;
    }
    if (search.label && search.label.trim() !== '') {
      searchParams = {itemId: search.label};
    }

    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.label && search.label.length > 0) {
      searchParams.label = search.label;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((results: any) => results as IdentificationSearchResult[])
      );

    return req;
  }

  public identificationSearchCount(search: SearchCriteria): Observable<number> {
    const url = this.baseSvc.customerUrl(`${search.dataset}/${PORTAL_USER_ROLE}/identification-view/search/count`);
    let searchParams: any = {};
    searchParams.start = search.startDate.toISOString();
    searchParams.end = search.endDate.toISOString();
    searchParams.dataset = search.dataset;

    if (search.label && search.label.trim() !== '') {
      searchParams = {itemId: search.label};
    }

    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.label && search.label.length > 0) {
      searchParams.label = search.label;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((count: any) => count as number)
      );

    return req;
  }

  public featurePrintCountsByDay(dataset: string, start: Date, end: Date): Observable<CountsByDay[]> {
    const url = this.baseSvc.customerUrl(`${dataset}/${PORTAL_USER_ROLE}/featureprints-count/total-by-day`);
    let searchParams: any = {};
    searchParams.start = start.toISOString();
    searchParams.end = end.toISOString();

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((results: any) => results as CountsByDay[])
      );

    return req;
  }

  public identificationCountsByDay(dataset: string, start: Date, end: Date): Observable<CountsByDay[]> {
    const url = this.baseSvc.customerUrl(`${dataset}/${PORTAL_USER_ROLE}/identifications-count/total-by-day`);
    let searchParams: any = {};
    searchParams.start = start.toISOString();
    searchParams.end = end.toISOString();

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({params: searchParams}))
      .pipe(
        map((results: any) => results as CountsByDay[])
      );

    return req;
  }

}
