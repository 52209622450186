import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PortalApiService} from '../../modules/record/portal-api.service';
import {Identification} from "../../modules/record/identification-models";
import {StockItemSelector} from "../../modules/record/feature-print-models";

export interface IdentificationWithDataset {
    identification: Identification;
    dataset: string;
}

@Component({
    selector: 'ae-identification',
    templateUrl: './identification.component.html',
    styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit {
    protected identificationId: string | null = null;
    dataset: string | null = null;
    protected matchedStockItemSelector: StockItemSelector | null = null;

    @Input() fullView = true;

    @Input() set identificationRecord(value: IdentificationWithDataset | null) {
        this.identification = value?.identification || null;
        this.dataset = value?.dataset || null;
        if (this.dataset && this?.identification?.bestMatchStockItemId) {
            this.matchedStockItemSelector = {
                stockItemId: this.identification.bestMatchStockItemId,
                dataset: this.dataset
            }
        }
        this.getPreview();
    }

    private identificationStream!: Subscription;
    public identification: Identification | null = null;
    public previewUrl: string | null = null;

    constructor(private recordApi: PortalApiService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.getDetail();
    }

    getDetail(): void {
        if (!this.identificationId || !this.dataset) {
            this.identificationId = this.route.snapshot.paramMap.get('identificationId') as string;
            this.dataset = this.route.snapshot.paramMap.get('dataset') as string;
        }

        if (this.identificationStream) {
            this.identificationStream.unsubscribe();
        }

        this.identificationStream = this.recordApi.getIdentification(this.identificationId, this.dataset).subscribe(identification => {
            this.identification = identification;
            this.getPreview();
            this.matchedStockItemSelector = {
                stockItemId: this.identification!.bestMatchStockItemId!,
                dataset: this.dataset!
            }

        });
    }

    getPreview(): void {
        if (this.identification && this.dataset) {
            this.previewUrl = this.recordApi.computePreviewUrl(this.identification.previewImageKey, this.dataset!);
        }
    }
}
