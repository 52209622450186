import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../user.service";

@Component({
    selector: 'ae-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    constructor(private api: UserService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit(): void {
        console.log("login on-init");
        this.processOauth();
    }

    private processOauth() {
        const redirectUrl = this.route.snapshot.queryParamMap.get("redirectUrl");
        this.api.setPostLoginRedirectUrl(redirectUrl || "");
        window.location.href = this.api.getOauth2LoginUrl();
    }

    ngOnDestroy(): void {
        //nothing to do...
    }
}
