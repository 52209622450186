import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../user.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {timer} from 'rxjs';
import {ConfigService} from "../../base/config.service";
import {DemoConfig} from "../../../app.module";
import {DatasetSourceRoles, SourceRole, UserInfo} from "../user-models";
import {GatewayException} from "../../base/gateway-exception";

export class UserStatus {
  enabled: boolean;
  label: string;

  constructor(enabled: boolean, label: string) {
    this.enabled = enabled;
    this.label = label;
  }
}

export const NO_ROLES = "NO_ROLES";

export const userFormValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const isNew = control.get('userIsNew');
  // console.log(control, isNew, isNew.value);
  if (isNew && isNew.value === true) {
    const userName = control.get('userName');
    const invalid = false;
    // console.log('valid?', invalid, pwd.value);
    return invalid ? {invalidUserName: true} : null;
  } else {
    return null;
  }
};

@Component({
  selector: 'ae-user-mgt',
  templateUrl: './user-mgt.component.html',
  styleUrls: ['./user-mgt.component.scss']
})
export class UserMgtComponent implements OnInit {

  constructor(private userService: UserService, private configSvc: ConfigService<DemoConfig>, formBuilder: FormBuilder) {
    this.filterForm = formBuilder.group({
      filterStatus: this.filterStatusFormCtl
    });

    this.userForm = formBuilder.group({
      userName: this.userNameFormCtl,
      userRoles: this.userSuperuserFormCtl,
      userEnabled: this.userEnabledFormCtl,
      userIsNew: this.userIsNewCtl
    }, {validators: userFormValidator});
  }

  @ViewChild('usersTop') usersTopElement!: ElementRef;
  @ViewChildren('usersList') usersListElements!: QueryList<ElementRef>;

  showFilter = true;
  currentUserId: string | null = null;
  currentUser: UserInfo | null = null;
  users: UserInfo[] | null = null;
  currentDataset: string | null = null;
  availableDatasets: DatasetSourceRoles[] | null = null;
  availableSourceRoles: string[] = [];

  ENABLED_STATUS = new UserStatus(true, 'Enabled');
  DISABLED_STATUS = new UserStatus(false, 'Disabled');
  statuses: UserStatus[] = [this.ENABLED_STATUS, this.DISABLED_STATUS];

  filterForm: FormGroup;
  filterStatusFormCtl = new FormControl();
  selectDatasetFormCtl = new FormControl()

  // user editing
  inEdit = false;
  showDelete = false;
  userFormTitle = '';
  userForm: FormGroup;
  userNameFormCtl = new FormControl();
  userSuperuserFormCtl = new FormControl();
  userEnabledFormCtl = new FormControl();
  userIsNewCtl = new FormControl();
  formErrors: string[] = [];


  ngOnInit(): void {
    const datasetSourceRolesSub = this.userService.listAssignableDataSets();
    datasetSourceRolesSub.subscribe({
      next: (datasets) => {
        this.availableDatasets = datasets;
        this.resetFilter();
      },
      error: error => {
        console.log('Error initializing user management datasets', error);
      }
    });
  }

  changeDataset(): void {
    this.clearCurrent();
    this.currentDataset = this.selectDatasetFormCtl.value;
    const dataset = this.availableDatasets?.find(ds => ds.name === this.currentDataset);
    this.availableSourceRoles = this.availableDatasets?.find(ds => ds.name === this.currentDataset)?.assignableSourceRoles || [];
  }

  protected clearCurrent(): void {
    this.currentUser = null;
    this.currentUserId = null;
    if (this.userIsNewCtl.value !== true) {
      this.cancelEdit();
    }
  }

  hasSourceRole(sourceRole: string): boolean {
    return this.currentUser?.sourceRoles?.map(sr => sr.sourceRole).includes(sourceRole) || false;
  }

  hideFilter(): void {
    this.showFilter = false;
  }

  resetFilter(): void {
    this.clearCurrent();
    this.currentDataset = null;
    this.users = null;
    const statuses = this.statuses
      .filter(it => it === this.ENABLED_STATUS)
      .map(it => it.enabled);
    const datasets = this.availableDatasets
      ?.map(d => d.name);
    this.filterStatusFormCtl.setValue(statuses);
    this.selectDatasetFormCtl.setValue(datasets);
  }

  doFilter(): void {
    this.users = null;
    this.showFilter = false;
    this.changeDataset();
    const formValue = this.filterForm.value;
    const status: boolean[] = formValue.filterStatus;
    this.userService.listUsers(this.currentDataset!).subscribe(users => {

      // filter the full list against the desired filter params
      const filtered = users
        .filter(user => status.some(s => s === user.enabled));

      this.users = filtered;

      if (filtered.length > 0) {
        this.hideFilter();
      }
    });
  }

  protected selectCurrentAndScroll(id: string, i: number, scroll: boolean): void {
    this.currentUserId = id;
    this.getAndSetupCurrentUser(id);
    this.showDelete = true;
    if (scroll && id) {
      timer(50).subscribe(() => {
        this.usersListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.usersTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected selectCurrent(id: string, i: number): void {
    const doScroll: boolean = this.currentUserId ? false : true;
    this.selectCurrentAndScroll(id, i, doScroll);
    this.showDelete = true;
  }

  private setCurrentUser(user: UserInfo): void {
    this.currentUser = user;
    this.userNameFormCtl.setValue(user.email);
    this.userSuperuserFormCtl.setValue(user.superuser);
    this.userEnabledFormCtl.setValue(user.enabled);
    this.userIsNewCtl.setValue(false);
    this.userFormTitle = 'Edit User';
    this.userNameFormCtl.disable();
    this.inEdit = true;
  }

  newUser(): void {
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    this.userIsNewCtl.setValue(true);
    this.userEnabledFormCtl.setValue(true);
    this.userFormTitle = 'Enter New User';
    this.inEdit = true;
    this.showDelete = false;
  }

  cancelEdit(): void {
    this.clearFormErrors();
    this.inEdit = false;
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    if (!this.users || this.users.length < 1) {
      this.showFilter = true;
    }
  }

  protected resetUserForm(): void {
    this.userFormTitle = '';
    this.userNameFormCtl.setValue('');
    this.userNameFormCtl.enable();
    this.userEnabledFormCtl.setValue(false);
    this.userSuperuserFormCtl.setValue(false);
    this.userIsNewCtl.setValue(null);
    this.userForm.reset();
  }

  formatYesNo(value?: boolean): string {
    return (value || false) ? "Yes" : "No";
  }

  changeSourceRole(isChecked: boolean, sourceRole: string): void {
    if (isChecked) {
      this.addSourceRole(sourceRole);
    } else {
      this.removeSourceRole(sourceRole);
    }
  }

  changeStatus(isEnabled: boolean) {
    if (this.currentUser) {
      //call backend
      this.userService.saveUser(this.currentUser?.email!, isEnabled).subscribe((user: UserInfo) => {
        this.getAndSetupCurrentUser(user.id);
        this.doFilter();
      }, (error: any) => console.log(error));
    }
  }

  getAndSetupCurrentUser(userId: string): void {
    this.userService.getUser(userId, this.currentDataset!).subscribe((user: UserInfo) => {
      this.currentUserId = userId;
      this.currentUser = user;
      this.setCurrentUser(this.currentUser);
    }, (error: any) => console.log(error))
  }

  addSourceRole(sourceRole: string): void {
    if (this.currentUser && this.currentDataset) {
      //call backend
      this.userService.addSourceRole(this.currentUser?.id!, sourceRole, this.currentDataset!).subscribe(() => {
        this.getAndSetupCurrentUser(this.currentUser?.id!);
        this.doFilter();
      }, (error: any) => console.log(error));
    }
  }

  removeSourceRole(sourceRole: string): void {
    if (this.currentUser && this.currentDataset) {
      //call backend
      this.userService.removeSourceRole(this.currentUser?.id!, sourceRole, this.currentDataset!).subscribe(() => {
        this.getAndSetupCurrentUser(this.currentUser?.id!);
        this.doFilter();
      }, (error: any) => console.log(error));
    }
  }

  createUser(): void {
    if (this.userForm.valid) {
      this.clearFormErrors();
      const frm = this.userForm.value;

      const _this = this;

      // create user
      if (frm.userIsNew) {
        this.userService.saveUser(frm.userName, frm.userEnabled).subscribe({
          next: user => {
            this.cancelEdit();
            this.doFilter();
          },
          error: ((error: GatewayException) => {
            console.log('ERROR creating user', error);
            if (!_this.formErrors.includes(error.messages?.join("\n") as string)) {
              _this.formErrors.push(error.messages?.join("\n") as string);
            }
          })
        });
      }
    }
  }

  canSubmit(): boolean {
    let can = false;
    if (this.userIsNewCtl.value === true) {
      can = this.userForm?.errors == null;
    } else {
      can = (this.userSuperuserFormCtl.dirty || this.userEnabledFormCtl.dirty)
        && this.userForm?.errors == null;
    }

    // console.log(this.userForm, this.userForm.dirty, this.userForm.valid,  this.userForm?.errors, this.userIsNewCtl.value, can);
    return can;
  }

  clearFormErrors(): void {
    this.formErrors = [];
  }

  formatRoles(roles: SourceRole[]): string {
    return roles?.map(sr => sr.sourceRole).join(", ");
  }
}
