<ae-nav></ae-nav>
<div>

  <div>
    <div class="search-filter">
      <ae-search-filter class="search-filter"
                        [searchTitle]="'Identifications Search'"
                        [includeDateSearch]="true"
                        [includeLabelSearch]="true"
                        [dataSetList]="datasetNames"
                        (searchEvent)="searchHandler($event)">
      </ae-search-filter>
    </div>

    <div #authTop class="search-filter">
      <mat-card *ngIf="searchResult && searchResult.length > 0">
        <div class="header-menu">
          <h3 class="header-menu-title">Identifications count: {{ totalAuthenticationsLength || 0 }}</h3>
          <button *ngIf="currentDisplayId" mat-button (click)="clearCapture();">
            clear selection
          </button>
        </div>

        <div class="scroll-pane scroll-pane-all"
             [ngClass]="{'scroll-pane-detail': currentDisplayId}">
          <table class="table table-sm table-hover table-striped">
            <thead>
            <tr>
              <th>Identification ID</th>
              <th>Identification Label</th>
              <th>Match</th>
              <th>Matched Stock Item Label</th>
              <th>Station</th>
              <th>User ID</th>
              <th>Created</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let a of searchResult; index as i" #authList
                [ngClass]="{'selected': currentDisplayId == a?.identificationId}"
                class="link-active"
                (click)="currentDisplayId == a.identificationId ? clearCapture() : viewCapture(i, a.identificationId)">
              <td>
                <a *ngIf="a.identificationId" class="link-active" target="_blank"
                   matTooltip="select link to open authentication in new tab"
                   [routerLink]="['/identification', this.searchDataset, a.identificationId]"> {{ a.identificationId }} </a>
                <span *ngIf="!a.identificationId">Not Available</span>
              </td>
              <td matTooltip="click row for details">{{ a.identificationLabel }}</td>
              <td matTooltip="click row for details">
                <mat-icon *ngIf="a.bestMatchStockItemId && a.identificationLabel == a.stockItemLabel" color="primary">check</mat-icon>
                <mat-icon class="mismatch" *ngIf="a.bestMatchStockItemId && a.identificationLabel != a.stockItemLabel" >warning</mat-icon>
                <mat-icon *ngIf="!a.bestMatchStockItemId" color="warn">close</mat-icon>
              </td>
              <td matTooltip="click row for details">{{ a.stockItemLabel }}</td>
              <td matTooltip="click row for details">{{ a.stationId }}</td>
              <td matTooltip="click row for details">{{ a.author }}</td>
              <td matTooltip="click row for details">{{ a.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
      <mat-paginator #paginator
                     [length]="totalAuthenticationsLength || 0"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     [showFirstLastButtons]="true"
                     [hidden]="totalAuthenticationsLength < 1"
                     (page)="pageHandler($event)"
      ></mat-paginator>

      <mat-card *ngIf="searchResult &&  searchResult.length === 0" class="no-match">
        <mat-card-content>
          No Matching Records
        </mat-card-content>
      </mat-card>

      <ae-identification *ngIf="currentDisplayId && currentIdentification"
                         [fullView]="false"
                         [identificationRecord]="currentIdentificationWithDataset"
      ></ae-identification>

    </div>
  </div>
</div>
