<mat-card>
    <mat-card-header mat-card-title>User Authentication</mat-card-header>
    <mat-card-content *ngIf="error">
        <div>
          {{errorMessage()}}
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="!isProcessingComplete()">
        Processing Authentication...
    </mat-card-content>
</mat-card>
