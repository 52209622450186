import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {AccessToken} from "./access-token";
import {Observable} from "rxjs/internal/Observable";
import {throwError} from "rxjs";
import {GatewayException} from "./gateway-exception";
import {ConfigService} from "./config.service";
import {AppConfig} from "./app-config";

@Injectable()
export class BaseService {

  constructor(private configSvc: ConfigService<AppConfig>) {
  }

  private accessToken: AccessToken | null = null;

  public isTokenExpired(): boolean {
    return this.getAccessToken().isTokenExpired();
  }

  public getAccessToken(): AccessToken {
    if (!this.accessToken) {
      this.loadToken();
    }
    return this.accessToken as AccessToken || null;
  }

  public clearAuthTokens(): void {
    this.accessToken = null;
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('token-type');
    localStorage.removeItem('access-token-expires');
    localStorage.removeItem('refresh-token-expires');
  }

  public setToken(token: AccessToken): void {
    this.accessToken = token;
    this.storeToken();
  }

  private storeToken(): void {
    if (this.accessToken) {
      localStorage.setItem('access-token', this.accessToken.token);
      localStorage.setItem('refresh-token', this.accessToken.refreshToken);
      localStorage.setItem('token-type', this.accessToken.tokenType);
      localStorage.setItem('access-token-expires', this.accessToken.tokenExpires.getTime().toString());
      localStorage.setItem('refresh-token-expires', this.accessToken.refreshExpires.getTime().toString());
    }
  }

  private loadToken(): void {
    const loadedToken = new AccessToken(
      localStorage.getItem('access-token') as string,
      this.localStorageDate(localStorage.getItem('access-token-expires') as string),
      localStorage.getItem('refresh-token') as string,
      this.localStorageDate(localStorage.getItem('refresh-token-expires') as string)
    );
    this.accessToken = loadedToken;
  }

  private localStorageDate(dateAsStringNumber: string): Date {
    const millis = parseInt(dateAsStringNumber);
    if (Number.isNaN(millis)) {
      return new Date(0);
    } else {
      return new Date(millis);
    }
  }

  public gatewayUrl(urlSuffix: string): string {
    return `${this.configSvc.getConfig().gatewayUrl}/${urlSuffix}`;
  }

  public customerUrl(urlSuffix: string) {
    const config = this.configSvc.getConfig();
    return `${config.gatewayUrl}/${config.customerName}/${urlSuffix}`;
  }

  public appConfig(): AppConfig {
    return this.configSvc.getConfig();
  }

  public bareAuthHeaders(): HttpHeaders {
    const token = this.getAccessToken();
    return new HttpHeaders({
      Authorization: `${token?.tokenType} ${token?.token}`
    });
  }

  public defaultHeaders(): HttpHeaders {
    const token = this.getAccessToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `${token?.tokenType} ${token?.token}`
    });
  }

  public defaultOptions(extras: object = {}): any {
    return {
      ...{headers: this.defaultHeaders()},
      ...extras
    };
  }

  public defaultOptionsWithSearch(searchParams: HttpParams): any {
    return {
      ...{headers: this.defaultHeaders()},
      ...{search: searchParams}
    };
  }

  /**
   * multipartHeaders() omits adds the auth header but omits content type to get
   * the boundary generated.
   *
   * Important: must use FordData for the payload.
   */
  public multipartHeaders(): HttpHeaders {
    const token = this.getAccessToken();
    return new HttpHeaders({
      // Important: omit Content-Type to get it generated with a boundary generated
      Accept: 'application/json',
      Authorization: `${token?.tokenType} ${token?.token}`
    });
  }

  public handleError(error: any): Observable<never> {
    console.log("base handleError:", error);
    let ae: GatewayException;
    if (error instanceof GatewayException) {
      ae = error;
    } else {
      ae = GatewayException.fromError(error);
    }
    return throwError(() => ae);
  }

}
