<ae-nav *ngIf="fullView"></ae-nav>
<div class="divider" *ngIf="!fullView">&nbsp;</div>
<div class="container-fluid record-header">
  <mat-card>
    <div class="center-title">
      <mat-card-header>
        <mat-card-title>
          Identification
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <mat-card-subtitle>
        Identification Label: {{ identification?.identificationLabel || 'N/A' }}
      </mat-card-subtitle>
    </mat-card-content>
  </mat-card>
</div>
<div class="container-fluid">
  <div class="row row-padded">
    <div class="col-md-3">
      <mat-card *ngIf="identification" [ngClass]="{'record-view-full': fullView}" class="record-card">
        <div>
          <table width="450px" class="table table-sm table-hover table-striped" >
            <thead>
            <tr style="border:none">
              <th colspan="2" class="record-title">Identification</th>
            </tr>
            </thead>
            <tbody>
            <tr class="preview-wrapper-row">
              <td class="preview-wrapper" colspan="2">
                <img alt="preview" *ngIf="previewUrl"
                     class="preview preview-sm" [src]="previewUrl | previewImage | async"
                >
              </td>
            </tr>
            <tr>
              <td class="preview-label">IdentificationId:</td>
              <td>{{identification.identificationId}}</td>
            </tr>
            <tr>
              <td class="preview-label">Identification Label:</td>
              <td>{{identification.identificationLabel}}</td>
            </tr>
            <tr>
              <td class="preview-label">Match Value:</td>
              <td>{{identification.matchValue | number: '1.1-1'}}</td>
            </tr>
            <tr>
              <td class="preview-label">Station:</td>
              <td>{{identification.stationId}} - {{identification.stationLocation}} - {{identification.stationVersion}}</td>
            </tr>
            <tr>
              <td class="preview-label">Author:</td>
              <td>{{identification.author}}</td>
            </tr>
            <tr>
              <td class="preview-label">Created</td>
              <td>{{identification.created | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
            </tr>
            <tr>
              <td class="preview-label">Identification Data</td>
              <td>
                <pre>{{identification.identificationAnnotationData | json}}</pre>
              </td>
            </tr>
            <tr>
              <td class="preview-label">Matched Stock Item Data</td>
              <td>
                <pre>{{identification.matchStockItemAnnotationData | json}}</pre>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </div>
    <div class="col-md-9">
      <div class="no-match" *ngIf="!(identification?.bestMatchStockItemId)">
        <mat-card>
          <mat-card-content>
            No Matching Item
          </mat-card-content>
        </mat-card>
      </div>
      <ae-stock-item *ngIf="identification?.bestMatchStockItemId"
                       [fullView]="false"
                       [showHeader]="false"
                       [stockItemSelector]="matchedStockItemSelector">
      </ae-stock-item>
    </div>
  </div>
</div>
