/*
{
  "principalGrant": {
    "id": "01905547-5c33-7d2a-ac7d-94d54f75437d",
      "email": "shannon@alitheon.com",
      "customerName": "richemont",
      "enabled": true,
      "roles": [
        "SUPERUSER"
      ],
      "sourceRoles": [
        {
          "userId": "01905541-22e1-70b9-8911-f6a68de384b1",
          "customerName": "richemont",
          "datasetName": "panerai",
          "sourceRole": "STATION-BASE",
          "created": "2024-07-29T19:13:53.703239Z",
          "createdBy": "authz-foundation"
        }
      ],
      "created": "2024-08-13T11:04:13.522252Z",
      "createdBy": "authz-foundation",
      "lastModified": "2024-08-13T19:40:26.204379Z",
      "modifiedBy": "authz-foundation"
  },
  "tokenDetails": {
    "accessToken": "...",
    "expiresIn": 86400,
    "refreshToken": "...",
    "refreshTokenExpiresIn": 2592000
  }
}
*/

export class TokenInfo {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  tokenType: string = "bearer";

  constructor(
    access_token: string,
    expires_in: number,
    refresh_token: string,
    refresh_expires_in: number
  ) {
    this.accessToken = access_token
    this.expiresIn = expires_in
    this.refreshToken = refresh_token
    this.refreshTokenExpiresIn = refresh_expires_in
  }
}

export class AccessToken {
  token: string;
  tokenExpires: Date;
  refreshToken: string;
  refreshExpires: Date;
  tokenType: string = "bearer";

  constructor(token: string, tokenExpires: Date, refreshToken: string, refreshExpires: Date) {
    this.token = token;
    this.tokenExpires = tokenExpires;
    this.refreshToken = refreshToken;
    this.refreshExpires = refreshExpires;
  }

  public isTokenExpired(): boolean {
    let expired = true;
    try {
      const expires = (this.tokenExpires.getTime() || this.expiredOrMissingTokenDate().getTime());
      const now = Date.now();
      expired = (expires < now);
    } catch (e) {
      console.log("error checking token expire", e);
    }
    return expired;
  }

  static fromTokenInfo(tokenInfo: TokenInfo) {
    return new AccessToken(
      tokenInfo.accessToken, this.calculateExpireDate(tokenInfo.expiresIn),
      tokenInfo.refreshToken, this.calculateExpireDate(tokenInfo.refreshTokenExpiresIn)
    );
  }

  static calculateExpireDate(expiresInSeconds: number): Date {
    return new Date(Date.now() + (expiresInSeconds * 1000));
  }

  private expiredOrMissingTokenDate(): Date {
    return new Date(0);
  }

}

