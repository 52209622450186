<ae-nav *ngIf="fullView"></ae-nav>
<div *ngIf="showHeader && stockItemId" class="container-fluid record-header">
  <mat-card>
    <div class="center-title">
      <mat-card-header>
        <mat-card-title>
          StockItem FeaturePrint: {{ stockItem?.stockItemAnnotationData?.label }}
        </mat-card-title>
      </mat-card-header>
    </div>
  </mat-card>
</div>
<div class="container-fluid" *ngIf="stockItemId">
  <div class="row row-padded">
    <div class="col-md-6">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let facet of facets" hideToggle>
          <mat-expansion-panel-header>{{facet.facetName}} - {{facet.status}}</mat-expansion-panel-header>
          <ae-facet [facetInput]="{facet: facet!, dataset: dataset!}"></ae-facet>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-md-6">
      <mat-card class="record-card">
        <table class="table table-sm table-hover table-striped">
          <thead>
          <tr style="border:none">
            <th colspan="2" class="record-title">Stock Item Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Label:</td>
            <td class="detail-label">{{ stockItem?.stockItemAnnotationData?.label }}</td>
          </tr>
          <tr>
            <td>Commercial Reference:</td>
            <td class="detail-label">{{ getCommercialRef() }}</td>
          </tr>
          <tr>
            <td>Serial Number:</td>
            <td class="detail-label">{{ getSerialNumber() }}</td>
          </tr>
          <tr>
            <td>Stock Item Data:</td>
            <td class="detail-label">
              <pre>{{ stockItem?.stockItemAnnotationData | json }}</pre>
            </td>
          </tr>

          <tr>
            <td>Stock Item Set Data:</td>
            <td class="detail-label">
              <pre>{{ stockItem?.stockItemSetAnnotationData | json }}</pre>
            </td>
          </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
  </div>
</div>
<div class=container-fluid *ngIf="!stockItemId">
  <mat-card>
    <mat-card-content>
      Missing
    </mat-card-content>
  </mat-card>
</div>
