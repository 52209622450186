<mat-toolbar color="primary" class="bg-gradient">
  <div class="header-logo" routerLink="/dashboard">
    <img alt="demo" src="/assets/alitheon-logo.png">
  </div>
  <span class="header-spacer">
      <a class="header-link" routerLink="/dashboard"
         routerLinkActive="header-link-active">Dashboard</a> |
      <a class="header-link" routerLink="/identifications" routerLinkActive="header-link-active">Identifications</a> |
      <a class="header-link" routerLink="/featurePrints" routerLinkActive="header-link-active">FeaturePrints</a> |
      <a class="header-link" *ngIf="canManage" routerLink="/user-mgt"
         routerLinkActive="header-link-active">User management | </a>
      <a class="header-link" routerLink="/logout" routerLinkActive="header-link-active">Logout</a>
</span>
  <div class="text-right">
    <div>
      <sup>{{now | date: 'MMMM d, y h:mm:ss'}}</sup>
    </div>
    <div>
      <sup>last updated: {{lastRefreshed | date: 'h:mm:ss'}} </sup>
    </div>
  </div>
</mat-toolbar>
